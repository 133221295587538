import { useId } from 'react';
import Autosuggest, { OnSuggestionSelected, RenderSuggestionsContainerParams } from 'react-autosuggest';
import styled, { css } from 'styled-components';
import SearchBarSuggestion from 'src/modules/search/shared/components/searchBarSuggestion';
import SearchBarInput from 'src/modules/search/shared/components/searchBarInput';
import { text } from 'styles-js/mixins/typography';
type Props = {
  className?: string;
  dataTestId?: string;
  isFocus: boolean;
  isSmall?: boolean;
  placeholder: string;
  suggestions: string[];
  value: string;
  setValue: (value: string) => void;
  setSuggestions: (suggestions: string[]) => void;
  onSuggestionsFetchRequested: ({
    value
  }: {
    value: string;
  }) => void;
  onClick?: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>, suggestion?: string) => void;
  setIsFocus: (value: boolean) => void;
  renderSuggestionsContainer?: (params: RenderSuggestionsContainerParams) => React.ReactNode;
};
export function SearchBarForm({
  className,
  dataTestId,
  isFocus,
  isSmall,
  placeholder,
  setIsFocus,
  suggestions,
  value,
  setSuggestions,
  setValue,
  onClick,
  onSubmit,
  onSuggestionsFetchRequested,
  renderSuggestionsContainer
}: Props) {
  const id = useId();
  const onSuggestionSelected: OnSuggestionSelected<string> = (e, {
    suggestion
  }) => {
    setValue(suggestion);
    onSubmit(e, suggestion);
  };
  return <Form $isSmall={isSmall} className={className} data-testid={dataTestId} onSubmit={onSubmit} data-sentry-element="Form" data-sentry-component="SearchBarForm" data-sentry-source-file="searchBarForm.tsx">
      <Autosuggest getSuggestionValue={suggestion => suggestion} id={id} inputProps={{
      placeholder,
      value,
      onClick,
      onChange: (_, {
        newValue
      }) => setValue(newValue),
      onBlur: () => setIsFocus(false),
      onFocus: () => setIsFocus(true)
    }} renderInputComponent={renderInputComponentProps => <SearchBarInput isFocus={isFocus} renderInputComponentProps={renderInputComponentProps} value={value} />} renderSuggestion={text => <SearchBarSuggestion text={text} value={value} />} renderSuggestionsContainer={renderSuggestionsContainer} suggestions={suggestions} onSuggestionsClearRequested={() => setSuggestions([])} onSuggestionSelected={onSuggestionSelected} onSuggestionsFetchRequested={onSuggestionsFetchRequested} data-sentry-element="Autosuggest" data-sentry-source-file="searchBarForm.tsx" />
    </Form>;
}
const Form = styled.form<{
  $isSmall?: boolean;
}>`
  position: relative;
  height: 48px;
  width: 100%;
  max-width: 700px;

  .react-autosuggest__container {
    height: 100%;
  }
  .react-autosuggest__suggestions-container--open {
    border-radius: 4px;
    border: 1px solid ${({
  theme
}) => theme.colorGreyLight};
    box-shadow: ${({
  theme
}) => theme.shadowDefault};
    background-color: ${({
  theme
}) => theme.colorWhite};
    position: absolute;
    z-index: 200;
    top: 48px;
    width: 100%;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .react-autosuggest__suggestion {
    ${text}
    cursor: pointer;
    padding: 10px 15px;

  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({
  theme
}) => theme.colorBlueBackground};
  }

  ${({
  $isSmall
}) => $isSmall && css`
    height: 36px;
    .react-autosuggest__suggestions-container--open {
      top: 36px;
    }
    .react-autosuggest__input {
      font-size: 16px;
    }
    button[type="submit"] {
      width: 42px;
      font-size: 18px;
    }
  `}
`;