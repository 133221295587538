globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"4c710450096619619216ae53e1441fd937843ed5"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a user loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import sentryConfig from 'config/sentry';
import config from 'config/config';

if (config.environment === 'production') {
  Sentry.init({
    dsn: sentryConfig.dsn,
    environment: config.environment,
    ignoreErrors: sentryConfig.ignoreErrors,
    // Called for message and error events
    beforeSend(event, hint) {
      const error = hint.originalException;
      // [PROD-2052] If the error isn't coming from Axios, do not send the 'endpoint' custom tag
      if (event.exception?.values?.[0]?.type === 'AxiosError') {
        if (!error) return event;
        const url = (error as AxiosError).config?.url?.replace('https://healthunlocked.com', '');
        return {
          ...event,
          tags: { endpoint: `${(error as AxiosError).config?.method?.toUpperCase()} ${url}` },
        };
      } else {
        delete event.tags;
        return event;
      }
    },
  });
}
