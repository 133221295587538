import { FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';
import i18n from 'src/utils/translate';
import { fetchCommunities, searchCommunities } from 'src/modules/shared/modal/components/signup/actions';
import { useDispatch, useSelector } from 'src/store/store';
import { getPublic } from 'src/utils/api';
import { SearchBarForm } from 'src/modules/search/shared/components/searchBarForm';
export default function FindCommunitySearchBar() {
  const dispatch = useDispatch();
  const {
    isNoResults
  } = useSelector(({
    modals
  }) => modals.signupOnboarding);
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  useEffect(() => {
    if (value.trim() === '' || value === null) {
      dispatch(fetchCommunities());
    }
  }, [value, dispatch]);
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsFocus(false);
    dispatch(searchCommunities(value));
  };
  const onSuggestionsFetchRequested = throttle(async ({
    value = ''
  }) => {
    if (value.length <= 1) return;
    try {
      const {
        data
      } = (await getPublic(`search/autocomplete?q=${value}`) as {
        data: string[];
      });
      setSuggestions(data.slice(0, 10).map(suggestion => suggestion.toLowerCase()));
    } catch (_) {
      setSuggestions([]);
    }
  }, 500);
  return <>
      <SearchBarForm isFocus={isFocus} placeholder={i18n.t('e.g. conditions, health interests')} setIsFocus={setIsFocus} setSuggestions={setSuggestions} setValue={setValue} suggestions={suggestions} value={value} onSubmit={onSubmit} onSuggestionsFetchRequested={onSuggestionsFetchRequested} data-sentry-element="SearchBarForm" data-sentry-source-file="findCommunitySearchBar.tsx" />
      {isNoResults && <EmptyState data-testid="signup-find-communities-empty-state">
          <p>{i18n.t('0 public communities')}</p>
          <p>{i18n.t(`Sorry, we don't have any public communities matching `) + value}</p>
          <p>{i18n.t('Try broadening your search results to get more results')}</p>
        </EmptyState>}
    </>;
}
const EmptyState = styled.div`
  margin-top: 25px;
  p {
    margin-bottom: 10px;
  }
`;