import { Notification } from 'src/constants/notifications';
import { Gender } from 'src/utils/profileUtil';

export type FollowedCommunity = {
  communityId: number;
  communityType: string;
  logoImageHash: string;
  name: string;
  slug: string;
};

export type CurrentUser = {
  avatarImageUrl: string;
  dateCreated: string;
  email?: string;
  followedCommunities: FollowedCommunity[];
  followedUserIds: number[];
  isAccountPreferenceUpdating?: boolean;
  isCommunityMemberOrHUStaff?: boolean;
  isSharingEnabled: boolean;
  isUserFollowsEnabled: boolean;
  isUsersImFollowingPublic: boolean;
  locale: string;
  permissions: Permission[];
  roles?: Role[];
  unverifiedEmail?: string;
  userId: number;
  userImageHash: string;
  username: string;
};

export type Member = {
  age: number;
  bio: string;
  country: string;
  gender: Gender;
  healthInterests: { name: string }[];
  username: string;
};

export type CommunityMember = Member & {
  avatar: string;
  avatarMedium: string;
  id: number;
  profileImageSmall2x: string;
  roles: Role[];
  userId: number;
};

export type ExtendedMember = Member & {
  isFollowedByCurrentUser?: boolean;
  isUserFollowsEnabled?: boolean;
  profileImageHash?: string;
  profileImageSmall2x?: string;
  roles?: string[];
  userId?: number,
}

export enum Role {
  Administrator = 'Administrator',
  HealthUnlocked = 'HealthUnlocked',
  Moderator = 'Moderator',
  Partner = 'Partner',
}

export enum Permission {
  CreatePoll = 'CreatePoll',
  CreatePost = 'CreatePost',
  CreateUserRole = 'CreateUserRole',
  DeletePoll = 'DeletePoll',
  DeletePost = 'DeletePost',
  EditGaTrackingCode = 'EditGaTrackingCode',
  EditPoll = 'EditPoll',
  EditPost = 'EditPost',
  FeaturedPostsAccess = 'FeaturedPostsAccess',
  LockPost = 'LockPost',
  PinPost = 'PinPost',
  RateItem = 'RateItem',
  SendReportAbuse = 'SendReportAbuse',
  ViewAdminMenu = 'ViewAdminMenu',
}

export type NotificationPreference = {
  enabled: boolean;
  notificationId: Notification;
}

export type UpdatePreferencesProps = {
  isSharingEnabled: boolean;
} | {
  isUserFollowsEnabled: boolean;
} | {
  isUsersImFollowingPublic: boolean;
}
