// @ts-strict-ignore
import Router from 'next/router';

export const performSearch = (
  query: string,
  communityId = 'all',
  communitySlug?: string | null,
  searchType = 'posts',
): void => {
  if (query) {
    switch (searchType) {
      case 'communities':
        redirectToSearchCommunityPage({ query });
        break;
      case 'members':
      case 'posts':
      default:
        redirectToSearchPage({ query, communityId, communitySlug, searchType });
        break;
    }
  }
};

export const redirectToSearchPage = ({
  communityId = 'all',
  communitySlug,
  query,
  searchType = 'posts',
}: {
  communityId?: string | number;
  communitySlug?: string;
  query: string;
  searchType?: string;
}): void => {
  if (communitySlug) {
    Router.push(
      { pathname: '/community/communitySearch', query: { communitySlug, searchType, query } },
      { pathname: `/${communitySlug}/search/${searchType}`, query: { query } },
      { scroll: false },
    );
  } else {
    Router.push(
      { pathname: '/search', query: { query, community: communityId } },
      { pathname: `/search/${searchType}`, query: { query, community: communityId } },
      { scroll: false },
    );
  }
};

export const redirectToSearchCommunityPage = ({ query, page = 1 }: { query: string, page?: number }): void => {
  Router.push(
    `/search?searchType=communities&query=${query}&page=${page}`,
    `/search/communities?query=${query}&page=${page}`,
    { scroll: false },
  );
};

export const getExpectedPostUrl = (post, communitySlug, currentUrl, host): string => {
  const { search = '' } = new URL(currentUrl, `http://${host}/`);
  const privatePrefix = post.isPrivate ? '/private' : '';
  return `/${communitySlug}/posts${privatePrefix}/${post.id}/${post.urlEncodedTitle}${search}`;
};

export const redirectToPostsPage = ({ communitySlug, filter }: { communitySlug: string; filter?: string; }): void => {
  const filterHash = filter ? `#${filter}` : '';
  Router.push(
    `/community/communityPosts?communitySlug=${communitySlug}`,
    `/${communitySlug}/posts${filterHash}`,
    { shallow: true },
  );
};

export const redirectToPostsTopicPage = ({ communitySlug, topicName, filter }: { communitySlug: string; topicName: string; filter?: string; }): void => {
  const filterHash = filter ? `#${filter}` : '';
  Router.push(
    `/community/communityPosts?communitySlug=${communitySlug}&topicName=${topicName}`,
    `/${communitySlug}/posts/topic/${topicName}${filterHash}`,
    { shallow: true },
  );
};

export const redirectToPollsPage = (communitySlug): void => {
  Router.push(
    `/community/communityPosts?communitySlug=${communitySlug}`,
    `/${communitySlug}/polls`,
    { shallow: true },
  );
};

export const redirectToMembersPage = (communitySlug, filter = 'active', page = 1): void => {
  Router.push(`/${communitySlug}/members?filter=${filter}&page=${page}`, null, { scroll: false });
};

// Removing recipient from URL once it's processed. We don't want to leak 'recipient'
// in referrer for social media actions - e.g. if users click on log in with fb/google
// Shallow routing has to be done after first render, page isn't replaced, only route state is changed
export const removeRecipientFromUrl = (): void => {
  const queryParamsArray = location.search.substring(1).split('&');
  const newQueryParamsArray = queryParamsArray.filter(param => !param.includes('recipient'));
  const newQueryParamsString = newQueryParamsArray.join('&');
  const newPathname = Router.query.pathname ? Router.query.pathname : '/';
  const newQueryString = newQueryParamsString ? `?${newQueryParamsString}` : '';

  Router.push(
    { pathname: Router.pathname, query: Router.query },
    `${newPathname}${newQueryString}`,
    { shallow: true },
  );
};
