import { FormEvent, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { useSelector } from 'src/store/store';
import { performSearch } from 'src/utils/router';
import { getPublic } from 'src/utils/api';
import { SearchBarForm } from 'src/modules/search/shared/components/searchBarForm';
import i18n from 'src/utils/translate';
type Props = {
  autoFocus?: boolean;
  className?: string;
  dataTestId?: string;
  disableAutosuggest?: boolean;
  isCommunitySearch?: boolean;
  isSmall?: boolean;
  placeholder?: string;
  searchType?: 'posts' | 'members' | 'communities';
  trackingOnSubmit?: (query: string) => void;
  onClick?: () => void;
  onSearch?: () => void;
};
export default function GlobalSearch({
  autoFocus,
  className,
  dataTestId = 'search-bar',
  disableAutosuggest,
  isCommunitySearch,
  isSmall,
  placeholder = i18n.t('Search HealthUnlocked'),
  searchType = 'posts',
  trackingOnSubmit,
  onClick,
  onSearch
}: Props) {
  const currentCommunity = useSelector(({
    context
  }) => context.currentCommunity);
  const communityId = isCommunitySearch ? currentCommunity?.communityId : 'all';
  const communitySlug = isCommunitySearch ? currentCommunity?.slug : null;
  const searchQuery = decodeURIComponent(useSelector(({
    pages
  }) => pages?.search?.searchQuery) || '');
  const [isFocus, setIsFocus] = useState<boolean>(Boolean(autoFocus && !searchQuery));
  const [value, setValue] = useState(searchQuery);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  useEffect(() => {
    setValue(searchQuery);
  }, [searchQuery]);
  const onSubmit = (e: FormEvent, suggestion?: string) => {
    e?.preventDefault();
    const searchString = suggestion || value;
    performSearch(searchString, String(communityId), communitySlug, searchType);
    setIsFocus(false);
    trackingOnSubmit?.(searchString);
    onSearch?.();
  };
  const onSuggestionsFetchRequested = throttle(async ({
    value = ''
  }) => {
    if (value.length <= 1 || disableAutosuggest) return;
    try {
      const {
        data
      } = (await getPublic(`search/autocomplete?q=${value}`) as {
        data: string[];
      });
      setSuggestions(data.slice(0, 10).map(suggestion => suggestion.toLowerCase()));
    } catch (_) {
      setSuggestions([]);
    }
  }, 500);
  return <SearchBarForm className={className} dataTestId={dataTestId} isFocus={isFocus} isSmall={isSmall} placeholder={placeholder} setIsFocus={setIsFocus} setSuggestions={setSuggestions} setValue={setValue} suggestions={suggestions} value={value} onClick={onClick} onSubmit={onSubmit} onSuggestionsFetchRequested={onSuggestionsFetchRequested} data-sentry-element="SearchBarForm" data-sentry-component="GlobalSearch" data-sentry-source-file="searchBar.tsx" />;
}