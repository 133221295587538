import { captureMessage } from '@sentry/nextjs';
import { articleType } from 'src/constants/helpScoutArticles';
import { Featured } from 'src/constants/types';
import { prepLink } from 'src/utils/linkUtils';
import { getBranch } from 'src/utils/cookie';
import { getLocalStorageItem, setLocalStorageItem, Key } from 'src/utils/localStorage';

export const featuredPostJsFunctionRegex = /^js:(.+?)(\?(.*))?$/;

export const formatFeaturedPosts = (featuredPosts: Featured[]) => {
  return featuredPosts
    .filter(post => !post.branch || post.branch === getBranch());
};

export const formatFeaturedPostsForSinglePost = (featuredPosts: Featured[]) => {
  const huFeaturedPosts = featuredPosts
    .filter(post => post.featuredByCommunityName === 'HealthUnlocked');
  const firstCommunityFeaturedPost = featuredPosts
    .find(post => post.featuredByCommunityName !== 'HealthUnlocked');

  const secondFeaturedPost = firstCommunityFeaturedPost || huFeaturedPosts[1];

  return [huFeaturedPosts[0], secondFeaturedPost]
    .filter(x => !!x);
};

export const getDismissedFeaturedPostIds = () => {
  return JSON.parse(getLocalStorageItem(Key.featuredPostsDismissed) || '[]');
};

export const combinePostsWithFeaturedPosts = (posts: unknown[], featuredPosts: Featured[]) => {
  if (featuredPosts.length === 0) return posts;

  const insertFeaturedIndices = [1, 4];
  const combined = [];

  for (let i = 0; i < posts.length; i += 1) {
    if (insertFeaturedIndices.includes(i) && featuredPosts.length > 0) {
      combined.push(featuredPosts.shift());
    }
    combined.push(posts[i]);
  }

  return combined;
};

export const addToDismissList = (featuredPostId: number) => {
  const dismissed = JSON.parse(getLocalStorageItem(Key.featuredPostsDismissed) || '[]');
  dismissed.push(featuredPostId);
  setLocalStorageItem(Key.featuredPostsDismissed, JSON.stringify(dismissed));
};

export const isUniqueImpression = (featuredPostId: number) => {
  const uniqueImpressions = JSON.parse(getLocalStorageItem(Key.featuredPostUniqueImpressions) || '[]');
  return uniqueImpressions.indexOf(featuredPostId) === -1;
};

export const addToUniqueImpressionsList = (featuredPostId: number) => {
  const uniqueImpressions = JSON.parse(getLocalStorageItem(Key.featuredPostUniqueImpressions) || '[]');
  uniqueImpressions.push(featuredPostId);
  setLocalStorageItem(Key.featuredPostUniqueImpressions, JSON.stringify(uniqueImpressions));
};

export const getModalParams = (url: string): Record<string, string> | null => {
  const query = url.split('?')[1];
  if (!query) return null;
  const params = (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params: Record<string, string>, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
  if (Object.keys(params).length > 0) {
    return params;
  } else {
    return null;
  }
};

export const getHelpScoutArticle = (url: string, featuredPostId: number): articleType | null => {
  const article = url.split('=')[1];
  if (article) {
    return article as articleType;
  } else {
    captureMessage(`Featured Post ${featuredPostId} URL error: ${url}`);
    return null;
  }
};

export const formatLink = (url?: string): string => {
  return getCtaType(url) === 'link' ? prepLink(url) : '';
};

const getFunctionName = (callToActionUrl: string) => {
  const matches = featuredPostJsFunctionRegex.exec(callToActionUrl);
  const functionName = Array.isArray(matches) && matches[1];
  return functionName;
};

const isModalCta = (url?: string): boolean => { // js:openModal?popup=${popupId}
  return Boolean(url?.startsWith('js:') && getFunctionName(url) === 'openModal');
};

const isHelpScoutCta = (url?: string): boolean => { // js:helpscout?article=${articleId || articleSlug}
  return Boolean(url?.startsWith('js:') && getFunctionName(url) === 'helpscout');
};

const isMailtoCta = (url?: string): boolean => { // mailto:${email}
  return Boolean(url?.startsWith('mailto:'));
};

const isOldModalCta = (url?: string): boolean => { // http://www.openmod.al
  return Boolean(url?.startsWith('http://www.openmod.al'));
};

type CtaType = 'modal' | 'helpscout' | 'mailto' | 'oldModal' | 'link';

export const getCtaType = (url?: string): CtaType => {
  if (isModalCta(url)) {
    return 'modal';
  } else if (isHelpScoutCta(url)) {
    return 'helpscout';
  } else if (isMailtoCta(url)) {
    return 'mailto';
  } else if (isOldModalCta(url)) {
    return 'oldModal';
  } else {
    return 'link';
  }
};
