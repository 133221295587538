import Link from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'src/store/store';
import i18n from 'src/utils/translate';
import config from 'config/config';
import { showSignupModal, showLoginModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent, trackFootfallEventWithHrefDelay } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import Button from 'src/components/buttons/button';
import { textLinkSecondary } from 'styles-js/mixins/typography';
import { useMediaQuery } from 'src/utils/mediaQuery';
import GlobalSearch from 'src/modules/search/shared/components/searchBar';
export default function VisitorSiteBar(): JSX.Element {
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(false);
  const isUnderScreenXsmall = useMediaQuery('underScreenXsmall');
  const isMobileSearching = isSearching && isUnderScreenXsmall;
  return <SiteBar aria-label="main navigation" data-sentry-element="SiteBar" data-sentry-component="VisitorSiteBar" data-sentry-source-file="visitorSiteBar.tsx">
      <SiteBarInner data-sentry-element="SiteBarInner" data-sentry-source-file="visitorSiteBar.tsx">
        {!isMobileSearching && <Link href="/" itemProp="url" itemScope={true} itemType="https://schema.org/Organization" onClick={() => {
        trackFootfallEvent(Event.Clicked, {
          clickMetadata: {
            section: Section.HuLogo
          },
          clickType: 'visitor-sitebar'
        });
      }}>
            <DesktopLogo alt="HealthUnlocked logo" height="18" itemProp="logo" src={`${config.cdn.logos}healthunlocked.svg`} width="188" />
            <MobileLogo alt="HealthUnlocked" height="32" src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} width="32" />
          </Link>}

        <StyledSearchBar $isMobileSearching={isMobileSearching} isSmall={true} placeholder={isUnderScreenXsmall ? i18n.t('Search') : i18n.t('Search HealthUnlocked')} onClick={() => {
        setIsSearching(true);
        trackFootfallEvent(Event.Clicked, {
          clickMetadata: {
            section: Section.SearchBar
          },
          clickType: 'visitor-sitebar'
        });
      }} onSearch={() => setIsSearching(false)} data-sentry-element="StyledSearchBar" data-sentry-source-file="visitorSiteBar.tsx" />

        {isMobileSearching && <PlainButton text={i18n.t('Cancel')} type="none" onClick={() => setIsSearching(false)} />}

        {!isMobileSearching && <>
            <a className="hidden-xs" href="https://about.healthunlocked.com" onClick={e => {
          e.preventDefault();
          trackFootfallEventWithHrefDelay('https://about.healthunlocked.com', Event.Clicked, {
            clickMetadata: {
              section: Section.AboutUs
            },
            clickType: 'visitor-sitebar'
          });
        }}>
              {i18n.t('About')}
            </a>

            <PlainButton dataTestId="visitor-sitebar-login-button" type="none" onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickMetadata: {
              section: Section.Login
            },
            clickType: 'visitor-sitebar'
          });
          dispatch(showLoginModal());
        }}>
              {i18n.t('Log in')}
            </PlainButton>

            <Button dataTestId="visitor-sitebar-signup-button" text={i18n.t('Join')} onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickMetadata: {
              section: Section.Join
            },
            clickType: 'visitor-sitebar'
          });
          dispatch(showSignupModal());
        }} />
          </>}

      </SiteBarInner>

    </SiteBar>;
}
const SiteBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${({
  theme
}) => theme.navHeightTop};
  line-height: ${({
  theme
}) => theme.navHeightTop};
  background-color: ${props => props.theme.colorWhite};
  z-index: ${props => props.theme.zindexLiftTop};
  border-bottom: ${props => props.theme.greyLine};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
const SiteBarInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  @media (${props => props.theme.underScreenSmall}) {
    padding: 0 5px;
    gap: 5px;
  }
  a {
    ${textLinkSecondary}
  }
`;
const StyledSearchBar = styled(GlobalSearch)<{
  $isMobileSearching: boolean;
}>`
  max-width: ${({
  $isMobileSearching
}) => $isMobileSearching ? '100%' : '225px'};
  margin-right: auto;
  margin-left: 5px;
`;
const PlainButton = styled(Button)`
  ${textLinkSecondary}
`;
const DesktopLogo = styled.img`
  @media (${props => props.theme.underScreenSmall}) {
    display: none;
  }
`;
const MobileLogo = styled.img`
  @media (${props => props.theme.overScreenSmall}) {
    display: none;
  }
`;