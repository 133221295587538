// @ts-strict-ignore
import i18n from 'src/utils/translate';
import ButtonDropdown from 'src/components/buttons/buttonDropdown';
import FollowButton from 'src/components/buttons/followButton/followButton';
import { useDispatch, useSelector } from 'src/store/store';
import { useIsCommunityMember, userHasRole } from 'src/utils/context';
import Button from 'src/components/buttons/button';
import { showModal } from 'src/modules/shared/modal/actions';
import { ModalType } from 'src/constants/modalTypes';
import { Role } from 'src/constants/types';
import { useBranch } from 'src/utils/cookie';
import { useMediaQuery } from 'src/utils/mediaQuery';
export default function AdminActions(): JSX.Element {
  const dispatch = useDispatch();
  const currentCommunity = useSelector(state => state.context.currentCommunity);
  const currentUser = useSelector(state => state.context.currentUser);
  const isCommunityMember = useIsCommunityMember();
  const isHUStaff = userHasRole(currentUser, [Role.HealthUnlocked]);
  const isB = useBranch() === 'B';
  const isMobile = useMediaQuery('underScreenSmall');
  // As we're not using DropdownItem (from reactstrap) in this dropdown, we need
  // to add tabindex & role manually for the 2 buttons to be navigable by keyboard
  return <ButtonDropdown isRightAligned={isB && isMobile} text={isB && isMobile && isCommunityMember ? i18n.t('Joined') : i18n.t('More')} data-sentry-element="ButtonDropdown" data-sentry-component="AdminActions" data-sentry-source-file="adminActions.tsx">
      <FollowButton buttonType="link" className="dropdown-item" community={currentCommunity} followType="community" hasIcon={false} isFollowing={isCommunityMember} role="menuitem" tabIndex={0} unFollowText={isB && isMobile && isCommunityMember ? i18n.t('Leave community') : undefined} data-sentry-element="FollowButton" data-sentry-source-file="adminActions.tsx" />
      {isHUStaff && <Button className="dropdown-item" icon="cog" iconPosition="right" role="menuitem" tabIndex={0} type="link" onClick={() => dispatch(showModal({
      modalType: ModalType.CommunityInvites
    }))}>
          {i18n.t('Invite')}
        </Button>}
    </ButtonDropdown>;
}